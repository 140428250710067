import axios from 'axios';
import { parseResponseData } from '@/http/parsers/parse_response';

const commissionV1Url = 'v1/commissions';

class CommissionService {
  constructor() {
    this.axios = axios;
  }

  async getStatements(params) {
    return this.axios.get(`${commissionV1Url}/statements`, { params }).then(parseResponseData);
  }

  async getStatement(statementId) {
    return this.axios.get(`${commissionV1Url}/statements/${statementId}`).then(parseResponseData);
  }

  async getStatementSummary(statementId) {
    return this.axios.get(`${commissionV1Url}/statements/${statementId}/summary`).then(parseResponseData);
  }

  async createStatements() {
    return this.axios.post(`${commissionV1Url}/statements`).then(parseResponseData);
  }

  async getTransactions(params) {
    return this.axios.get(`${commissionV1Url}/transactions`, { params }).then(parseResponseData);
  }

  async exportStatementSummary(statementID) {
    return this.axios.get(`${commissionV1Url}/statements/${statementID}/summary/export`).then(parseResponseData);
  }

  async exportStatements(params) {
    return this.axios.get(`${commissionV1Url}/statements/export`, { params, responseType: 'arraybuffer' });
  }

  async exportTransactions(params) {
    return this.axios.get(`${commissionV1Url}/transactions/export`, { params }).then(parseResponseData);
  }

  async getDeals(params) {
    return this.axios.get(`${commissionV1Url}/deals`, { params }).then(parseResponseData);
  }

  async getDeal(dealID) {
    return this.axios.get(`${commissionV1Url}/deals/${dealID}`).then(parseResponseData);
  }

  async updateDeal(dealID, body) {
    return this.axios.patch(`${commissionV1Url}/deals/${dealID}`, body).then(parseResponseData);
  }

  async createRVP(body) {
    return this.axios.post(`${commissionV1Url}/rvps`, body).then(parseResponseData);
  }

  async createRVPPlan(id, body) {
    return this.axios.post(`${commissionV1Url}/rvps/${id}/plan`, body).then(parseResponseData);
  }

  async getRVPs(params) {
    return this.axios.get(`${commissionV1Url}/rvps`, { params }).then(parseResponseData);
  }

  async getRVP(id) {
    return this.axios.get(`${commissionV1Url}/rvps/${id}`).then(parseResponseData);
  }

  async updateRVP(id, body) {
    return this.axios.put(`${commissionV1Url}/rvps/${id}`, body).then(parseResponseData);
  }

  async approveTransaction(id) {
    return this.axios.put(`${commissionV1Url}/transactions/approve/${id}`).then(parseResponseData);
  }
}

export default new CommissionService();
